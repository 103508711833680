<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { Form, FormHeader } from '@/components/Form'
import Row from '@/components/Structure/Row/Row.vue'
import Column from '@/components/Structure/Column/Column.vue'
import TextInput from '@/components/partials/Inputs/TextInput.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Warning from '@/components/partials/Warning.vue'

const { t } = useI18n()

defineProps({
  layoutTypes: { type: Object, required: true },
  header: { type: Boolean, default: true },
  error: { type: String, default: null },
  emailNotVerified: { type: Boolean, default: false },
})
const emit = defineEmits(['login', 'resend', 'change-layout'])

const rules = computed(() => ({
  email: {
    required,
    email,
  },
  password: {
    required,
    minLength: minLength(8), // TODO: change to 12
  },
}))

const state = reactive({
  email: '',
  password: '',
  emailResend: false,
})

const v$ = useVuelidate(rules, state)

function login() {
  v$.value.$touch()

  if (!v$.value.$invalid) {
    emit('login', {
      email: state.email,
      password: state.password,
    })
  }
}

function resend() {
  emit('resend')

  state.emailResend = true
}
</script>

<template>
  <Form>
    <Row>
      <Column>
        <FormHeader data-test-id="login-form-header">
          {{ t('modal-account.title.login') }}
        </FormHeader>
      </Column>
    </Row>

    <!-- Email -->
    <Row>
      <Column>
        <TextInput
          v-model.trim="v$.email.$model"
          data-test-id="email-input"
          :label="t('modal-account.inputs.email.label')"
          :placeholder="t('modal-account.inputs.email.placeholder')"
          :error="v$.email.$error ? t('errors.email.required') : null"
          @hit-enter="login"
        />
      </Column>
    </Row>

    <!-- Password -->
    <Row>
      <Column>
        <TextInput
          v-model.lazy="v$.password.$model"
          type="password"
          data-test-id="password-input"
          action-data-test-id="forgot-password"
          :label="t('modal-account.inputs.password.label')"
          :action="t('modal-account.inputs.password.forgot')"
          :placeholder="t('modal-account.inputs.password.placeholder')"
          :error="v$.password.$error ? t('errors.password.required') : null"
          @hit-enter="login"
          @action-click="$emit('change-layout', layoutTypes.RECOVER)"
        />
      </Column>
    </Row>

    <Row v-if="error">
      <Column>
        <Warning
          type="warning"
          data-test-id="login-form-error"
        >
          <p>{{ error }}.</p>
          <Button
            v-if="emailNotVerified && !state.emailResend"
            size="xs"
            type="secondary"
            class="resend-btn"
            data-test-id="login-form-resend"
            @click="resend"
          >
            {{
              t('modal-account.messages.click-to-resend-verification-email')
            }}
          </Button>
          <div
            v-if="emailNotVerified && state.emailResend"
            class="resend-message"
            data-test-id="login-form-cooldown"
          >
            {{ t('modal-account.messages.email-resend') }}
          </div>
        </Warning>
      </Column>
    </Row>

    <!-- Buttons -->
    <Row buttons>
      <Column class="mb-0">
        <Button
          :disabled="v$.$invalid"
          data-test-id="login-button"
          @click="login"
        >
          {{ t('modal-account.buttons.login') }}
        </Button>

        <Button
          type="text"
          data-test-id="register-button"
          @click="$emit('change-layout', layoutTypes.REGISTER)"
        >
          {{ t('modal-account.buttons.create-account') }}
        </Button>
      </Column>
    </Row>
  </Form>
</template>

<style scoped lang="scss">
.resend {
  &-btn {
    margin-top: 0.5rem;
  }

  &-message {
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--color-brand-primary);
  }
}
</style>
