<template>
  <Form tag="div">
    <Row>
      <Column>
        <FormHeader data-test-id="message-account-created-header">
          {{ t('forms.account-created-message.title') }}
        </FormHeader>
      </Column>
    </Row>
    <Row>
      <Column>
        <p>
          {{ t('forms.account-created-message.description') }}
        </p>
        <br>
        <p>{{ t('forms.account-created-message.no-email') }}</p>
      </Column>
    </Row>

    <Row v-if="resendError"
      data-test-id="resend-error"
    >
      <Column>
        <Warning type="warning">
          {{ resendError }}
        </Warning>
      </Column>
    </Row>

    <Row buttons>
      <Column>
        <Button data-test-id="back-to-login-button"
          @click="$emit('change-layout', layoutTypes.LOGIN)"
        >
          {{ t('buttons.log-in') }}
        </Button>
        <Button data-test-id="resend-email-button"
          type="text"
          :disabled="!!resendTimeout"
          @click="resend"
        >
          {{ t('buttons.resend-email') }}
          <span v-if="resendCoolDown > 0">&nbsp;{{ resendCoolDown }}s</span>
        </Button>
      </Column>
    </Row>
  </Form>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Form, FormHeader } from '@/components/Form'
import Row from '@/components/Structure/Row/Row.vue'
import Column from '@/components/Structure/Column/Column.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Warning from '@/components/partials/Warning.vue'

export default {
  name: 'MessageAccountCreated',

  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Row,
    Column,
    FormHeader,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    Warning,
  },

  props: {
    resendError: { type: String, default: null },
    layoutTypes: { type: Object, required: true },
  },

  emits: ['change-layout', 'resend'],

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },

  data() {
    return {
      resendTimeout: null,
      resendCoolDown: 0,
    }
  },

  methods: {
    resend() {
      const coolDown = 15
      this.resendCoolDown = coolDown

      this.resendTimeout = setInterval(() => {
        this.resendCoolDown--

        if (this.resendCoolDown === 0) {
          clearInterval(this.resendTimeout)
          this.resendTimeout = null
        }
      }, 1 * 1000)

      // bubble up
      this.$emit('resend')
    },
  },
}
</script>
