<template>
  <ClientOnly>
    <div>
      <LoginFormComponent v-if="layout === layoutTypes.LOGIN"
                          data-test-id="login-form"
                          :layout-types="layoutTypes"
                          :error="loginError"
                          :email-not-verified="emailNotVerified"
                          @change-layout="changeLayout"
                          @login="login"
                          @resend="resend"
      />

      <RecoverFormComponent v-if="layout === layoutTypes.RECOVER"
                            data-test-id="recover-form"
                            :layout-types="layoutTypes"
                            :error="recoverError"
                            @change-layout="changeLayout"
                            @recover="recover"
      />

      <RecoveringFormComponent v-if="layout === layoutTypes.RECOVERING"
                               data-test-id="recovering-form"
                               :email="$route.query.email"
                               :layout-types="layoutTypes"
                               :error="recoveringError"
                               @change-layout="changeLayout"
                               @recover="recoverPassword"
      />

      <RegisterFormComponent v-if="layout === layoutTypes.REGISTER"
                             data-test-id="register-form"
                             :layout-types="layoutTypes"
                             :error="registerError"
                             @change-layout="changeLayout"
                             @register="register"
      />

      <MessageEmailSent v-if="layout === layoutTypes.MESSAGE_EMAIL_SENT"
                        data-test-id="message-email-sent"
                        :layout-types="layoutTypes"
                        @change-layout="changeLayout"
      />

      <MessageAccountCreated v-if="layout === layoutTypes.MESSAGE_ACCOUNT_CREATED"
                             data-test-id="message-account-created"
                             :layout-types="layoutTypes"
                             :error="resendError"
                             @change-layout="changeLayout"
                             @resend="resend"
      />
    </div>
  </ClientOnly>
</template>

<script>
import { ClientOnly } from 'vite-plugin-vue-ssr'
import { useI18n } from 'vue-i18n'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import { useSentry } from '@/plugins/sentry'
import { TYPES as SNACK_TYPES } from '@/plugins/globals/snackbar'
import LoginFormComponent from './LoginFormComponent/LoginFormComponent.vue'
import RecoverFormComponent from './RecoverFormComponent/RecoverFormComponent.vue'
import RecoveringFormComponent from './RecoveringFormComponent/RecoveringFormComponent.vue'
import RegisterFormComponent from './RegisterFormComponent/RegisterFormComponent.vue'
import MessageEmailSent from './MessageEmailSent/MessageEmailSent.vue'
import MessageAccountCreated from './MessageAccountCreated/MessageAccountCreated.vue'
import { useGa } from '@/composables/useGa'
import { useProfileHttp } from '@/http/profileHttp'

export const layoutTypes = {
  LOGIN: 1,
  REGISTER: 2,
  RECOVER: 3,
  RECOVERING: 4,
  MESSAGE_EMAIL_SENT: 5,
  MESSAGE_ACCOUNT_CREATED: 6,
}

import { LOGIN_MODAL } from '@/store/modals'
import { USER_SIGNED_UP } from '@/plugins/globals/segment/handlers'

export default {
  name: 'LoginForm',

  components: {
    LoginFormComponent,
    RecoverFormComponent,
    RecoveringFormComponent,
    RegisterFormComponent,
    MessageEmailSent,
    MessageAccountCreated,
    ClientOnly,
  },

  props: {
    defaultLayout: {
      type: Number,
      default: layoutTypes.LOGIN,
    },
  },

  emits: ['login'],

  setup() {
    const { t } = useI18n()
    const { localizedRoute } = useLocalizedRouter()
    const profileHttp = useProfileHttp()
    const sentry = useSentry()
    const ga = useGa()

    return {
      t,
      localizedRoute,
      sentry,
      ga,
      profileHttp,
    }
  },

  data() {
    return {
      layoutTypes,
      navigatedLayout: null,

      // errors
      loginError: null,
      recoverError: null,
      recoveringError: null,
      registerError: null,
      resendError: null,
      emailNotVerified: false,

      // internal data
      email: null,
    }
  },

  computed: {
    layout() {
      return this.navigatedLayout || this.defaultLayout
    },
  },

  methods: {
    changeLayout(type) {
      this.navigatedLayout = type
    },

    // Component actions
    async login({ email, password }) {
      const { success, code, message } = await this.$store.dispatch(
        'user/login',
        {
          username: email,
          password,
        }
      )

      // success could also be undefined, if we're already fetching
      this.loginError =
        success === false ? message || this.t('errors.login.incorrect') : null

      this.emailNotVerified = code === 'EMAIL_NOT_VERIFIED'
      this.email = email

      if (!success) return

      await this.profileHttp.addresses()

      this.$store.dispatch('modals/toggle', {
        modal: LOGIN_MODAL,
        open: false,
      })

      this.ga.trackLogin()

      this.$emit('login')
    },

    async recover({ email }) {
      try {
        await this.$api.customer.recover({ email })

        this.changeLayout(layoutTypes.MESSAGE_EMAIL_SENT)
        this.recoverError = null
      } catch (e) {
        this.recoverError = this.t('errors.recover.general')
      }
    },

    async recoverPassword({ password, passwordConfirmation }) {
      const snackId = await this.$snackbar.message(
        this.t('forms.messages.recovering-password')
      )

      try {
        await this.$api.customer.recoverPassword({
          token: this.$route.query.token,
          email: this.$route.query.email,
          password,
          passwordConfirmation,
        })

        this.$snackbar.update(snackId, {
          title: this.t('forms.messages.recovering-password-success'),
          type: SNACK_TYPES.SUCCESS,
        })

        await this.$router.push(this.localizedRoute({ name: 'login' }))
      } catch (e) {
        this.$snackbar.update(snackId, {
          title: this.t('forms.messages.recovering-password-error'),
          type: SNACK_TYPES.ERROR,
        })

        const serverMessage = e?.response?.data?.message

        if (serverMessage) {
          this.$store.dispatch('modal/show', {
            title: this.t('forms.messages.recovering-password-error'),
            message: serverMessage,
            callback: [
              {
                label: this.t('terms.ok'),
              },
            ],
          })
        }
      }
    },

    async register({ name, email, password, passwordConfirmation }) {
      const snackId = await this.$snackbar.message(
        this.t('forms.messages.creating-account')
      )

      // Register post to server
      try {
        await this.$api.customer.register({
          name,
          email,
          password,
          passwordConfirmation,
        })

        this.$segment.handle(USER_SIGNED_UP, {
          name: name,
          email: email,
          $route: this.$route
        })

        this.ga.trackSignUp()

        this.$snackbar.update(snackId, {
          title: this.t('forms.messages.creating-account-success'),
          type: SNACK_TYPES.SUCCESS,
        })

        this.registerError = null
        this.changeLayout(layoutTypes.MESSAGE_ACCOUNT_CREATED)

        this.email = email
      } catch (e) {
        this.registerError = this.t('errors.register.general')

        this.$snackbar.update(snackId, {
          title: this.t('forms.messages.creating-account-error'),
          type: SNACK_TYPES.ERROR,
        })
      }
    },

    async resend() {
      try {
        await this.$api.customer.resendEmail({ email: this.email })
        this.resendError = null
      } catch (e) {
        this.sentry?.captureException(e)
        this.resendError = this.t('errors.resend.general')
      }
    },
  },
}
</script>
