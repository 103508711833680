<script setup lang="ts">
import { watch, nextTick, onMounted, ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import Icon from '@/components/partials/Icon.vue'
import { useResizeObserver } from '@/composables/resizeObserver'
import vCloseOnEscape from '@/plugins/directives/close_on_escape'

const props = defineProps({
  open: { type: Boolean, default: false },
  closable: { type: Boolean, default: true },
  animated: { type: Boolean, default: true },
  noScroll: { type: Boolean, default: false },
  noPadding: { type: Boolean, default: false },
  dataTestId: { type: String, default: null },
  modalClass: { type: String, default: null },
  modalContentClass: { type: String, default: null },
})

const emit = defineEmits(['close'])

watch(() => props.open, async (isOpen) => {
  if (isOpen) {
    await nextTick()

    closeOnDrag()
  }
})

onMounted(async () => {
  await nextTick()

  closeOnDrag()
})

function close() {
  if (props.open && props.closable) {
    emit('close')
  }
}

const draggableTrigger = ref<HTMLElement | null>(null)
const draggableTarget = ref<HTMLElement | null>(null)
const scrollable = ref<HTMLElement | null>(null)

function closeOnDrag() {
  let touchStartY = 0
  let touchStartScroll = 0
  const minDistance = 16 * 3 // 3rem

  function handleTouchStart(e: TouchEvent) {
    touchStartY = e.changedTouches[0].screenY
    touchStartScroll = scrollable.value?.scrollTop ?? 0
    draggableTarget.value?.removeAttribute('draggable-released', true)
  }

  function handleTouchMove(e: TouchEvent) {
    const touchY = e.changedTouches[0].screenY
    const movement = touchY - touchStartY

    // disable on modals that cannot be closed
    if (!props.closable) return

    // if the content of the modal is not scrolled to the top, stop the animation
    if (touchStartScroll !== 0) return

    if (draggableTarget.value !== null) {
      // animate dragging down
      // limit movement to downwards movement only
      draggableTarget.value.style.transform = `translateY(${movement > 0 ? movement : 0}px)`
    }

    // prevent scrolling
    if (movement > 0 && scrollable.value !== null) {
      scrollable.value.scrollTop = 0
    }
  }

  function handleTouchEnd(e: TouchEvent) {
    const touchendY = e.changedTouches[0].screenY

    if (
      // make sure the content of the modal is scrolled to the top
      touchStartScroll === 0 &&
      // check if the gesture is a swipe down
      touchendY > touchStartY &&
      // check if the distance is sufficient enough
      Math.abs(touchStartY - touchendY) >= minDistance &&
      // check if this modal can be closed
      props.closable
    ) {
      draggableTarget.value?.setAttribute('draggable-released', true)
      draggableTarget.value.style.transform = `translateY(${
        draggableTarget.value?.getBoundingClientRect().height
      }px)`

      // close modal after animation
      setTimeout(close, 200)
    } else {
      // limit movement to downwards movement only
      draggableTarget.value.style.transform = `translateY(0px)`
      // add css transition
      draggableTarget.value?.setAttribute('draggable-released', true)
    }
  }

  if (!draggableTrigger.value) return

  draggableTrigger.value?.addEventListener('touchstart', handleTouchStart)
  draggableTrigger.value?.addEventListener('touchmove', handleTouchMove)
  draggableTrigger.value?.addEventListener('touchend', handleTouchEnd)
}

const { val } = useResizeObserver()
</script>

<template>
  <div v-if="open"
    class="modal"
    :class="{
      [modalClass]: modalClass,
      'modal--no-bottom': !$slots.bottom
    }"
    :data-test-id="dataTestId"
  >
    <div class="modal__overlay animate__faster animate__fadeIn"
      :class="{
        animate__animated: animated,
      }"
    />
    <div ref="draggable-target"
      v-on-click-outside="close"
      v-close-on-escape="{ open, closable }"
      class="modal__container"
      @escape="close"
    >
      <div v-if="closable"
        v-taptic="'selection'"
        class="modal__close"
        :data-test-id="`${dataTestId}-close`"
        @click.stop="close"
      >
        <Icon name="cross" />
      </div>
      <div ref="draggable-trigger"
        class="modal__modal"
        :class="{
          animate__faster: animated,
          animate__animated: animated,
          animate__slideInUp: val <= 768,
        }"
      >
        <div v-if="closable"
          class="modal__drawer"
        />
        <div ref="scrollable"
          v-body-scroll-lock="open"
          :class="{
            [modalContentClass]: modalContentClass,
            modal__scroll: !noScroll,
            'modal__not-closable': !closable,
            'modal__no-scroll': noScroll,
            'modal--no-padding': noPadding,
          }"
        >
          <slot><!--Modal Content--></slot>
        </div>
        <div v-if="$slots.bottom"
          class="modal__bottom"
        >
          <slot name="bottom" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/interactions.scss';

.modal {
  $self: &;
  position: fixed;
  z-index: 50000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include lg-up {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
  }

  &__overlay {
    background-color: rgba(36, 46, 74, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__drawer {
    margin: 0.75rem auto;
    pointer-events: none;
    background-color: var(--color-neutral-quaternary);
    border-radius: 1rem;
    width: 3rem;
    height: 0.25rem;

    @include lg-up {
      display: none;
    }
  }

  &__container {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;

    &[draggable-released] {
      transition: transform 0.2s;
    }

    @include lg-up {
      position: relative;
      width: auto;
    }
  }

  &__modal {
    @include shadow-md;
    background: #ffffff;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    overflow: hidden;

    // fsdfdsfsd

    @include lg-up {
      @include radius;
      display: inline-block;
      line-height: 1.5rem;
      position: relative;
      max-height: calc(100vh - 10rem);
    }
  }

  &__scroll,
  &__no-scroll {
    // calculate: 100vh - close button - drawer - buttons - top inset - bottom inset
    max-height: calc(var(--app-height) - 48px - 42px - 73px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    padding: 1rem 2rem calc(3rem + env(safe-area-inset-bottom)) 2rem;

    @include lg-up {
      padding: 3rem;
      // calculate: 100vh - close button - drawer - buttons - top inset - bottom inset
      max-height: calc(100vh - 10rem - 6rem);
    }
  }

  &__not-closable {
    // overwrite the padding, as the drawer has disappeared
    padding-top: 2rem;
  }

  &__scroll {
    @include bistroo-scrollbar;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }

  &__bottom {
    padding: 1rem 2rem calc(1rem + env(safe-area-inset-bottom));
    border-top: 1px solid var(--color-neutral-quaternary);
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }

  &__close {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 50%;
    transition: transform 150ms ease-out;
    position: relative;
    z-index: 1;
    line-height: 0.7rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    align-self: flex-end;

    span {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include lg-up {
      margin-right: 0;
    }

    @include hover {
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: transform 150ms ease-out;
      }
    }

    @include icon-svg(0.75rem, 0.75rem);
  }

  // In case we have a modal without bottom, the max-height can grow to exclude the #bottom height
  &--no-bottom {
    #{ $self }__scroll,
    #{ $self }__no-scroll {
      overscroll-behavior: contain;

      // calculate: 100vh - close button - drawer - top inset - bottom inset
      max-height: calc(
        100vh - 60px - 42px - env(safe-area-inset-top) -
          env(safe-area-inset-bottom)
      );


      @include lg-up {
        max-height: calc(100vh - 10rem - 6rem);
      }
    }
  }

  &--no-padding {
    padding: 0;
  }
}
</style>
