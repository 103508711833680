<template>
  <Form>
    <Row>
      <Column>
        <FormHeader data-test-id="email-sent-header">
          {{ t('forms.message-email-sent.title') }}
        </FormHeader>
      </Column>
    </Row>
    <Row>
      <Column>
        {{ t('forms.message-email-sent.description') }}
      </Column>
    </Row>
    <Row buttons>
      <Column>
        <Button data-test-id="back-to-login-button"
          @click="$emit('change-layout', layoutTypes.LOGIN)"
        >
          {{ t('buttons.log-in') }}
        </Button>
      </Column>
    </Row>
  </Form>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Form, FormHeader } from '@/components/Form'
import Row from '@/components/Structure/Row/Row.vue'
import Column from '@/components/Structure/Column/Column.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'

export default {
  name: 'MessageEmailSent',

  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Row,
    Column,
    FormHeader,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
  },

  props: {
    layoutTypes: { type: Object, required: true },
  },

  emits: ['change-layout', 'resend'],

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },

  data() {
    return {
      resendTimeout: null,
      resendCooldown: 0,
    }
  },

  methods: {
    resend() {
      const cooldown = 15
      this.resendCooldown = cooldown

      this.resendTimeout = setInterval(() => {
        this.resendCooldown--

        if (this.resendCooldown === 0) {
          clearInterval(this.resendTimeout)
          this.resendTimeout = null
        }
      }, 1 * 1000)

      // bubble up
      this.$emit('resend')
    },
  },
}
</script>
