<template>
  <Modal
    no-scroll
    :data-test-id="$store.getters['modal/dataTestId'] || 'dynamic-modal'"
    :open="$store.getters['modal/hasError']"
    :closable="$store.getters['modal/closable']"
    @close="$store.dispatch('modal/reset')"
  >
    <div class="dynamic-modal">
      <div class="dynamic-modal__message">
        <Headline
          v-if="$store.getters['modal/title']"
          class="dynamic-modal__title"
        >
          <h1 class="h2 mt-0">
            {{ $store.getters['modal/title'] }}
          </h1>
        </Headline>
        <p class="dynamic-modal__message">
          {{ $store.getters['modal/message'] }}
        </p>
      </div>
    </div>

    <template
      v-if="$store.getters['modal/hasMultipleCallbacks']"
      #bottom
    >
      <Button
        v-for="(callback, index) in $store.getters['modal/callbacks']"
        :key="index"
        :data-test-id="callback.dataTestId"
        v-bind="callback.properties || {}"
        @click="$store.dispatch('modal/callback', index)"
      >
        {{ callback.label }}
      </Button>
    </template>
  </Modal>
</template>

<script>
// Component imports
import Modal from '@/components/Modals/Modal.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Headline from '@/components/partials/Headline.vue'

export default {
  name: 'ModalDynamic',

  components: { Modal, Button, Headline },
}
</script>

<style lang="scss" scoped>
.dynamic-modal {
  max-width: 26rem;

  &__message {
    font-size: 1rem;
  }
}
</style>
