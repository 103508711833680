import { onMounted, ref } from 'vue'

const val = ref(0)
let observed = false

let observer: ResizeObserver | null = null

if (!import.meta.env.SSR) {
  observer = new ResizeObserver(entries => {
    for (const entry of entries) {
      const width = entry.borderBoxSize?.[0].inlineSize;

      if (typeof width === 'number' && width !== val.value) {
        val.value = width
      }
    }
  })
}

export function useResizeObserver() {
  onMounted(() => {
    if (!observed && observer !== null) {
      observer.observe(document.body)

      observed = true
    }
  })

  return {
    val,
  }
}
