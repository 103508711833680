<template>
  <footer class="footer">
    <Container>
      <div class="footer__main">
        <Row>
          <div class="col-xs-12">
            <FooterFaq />
          </div>
        </Row>
        <Row>
          <Column class="col-lg-3">
            <div class="footer__nav">
              <b>{{ t('footer.discover-bistroo') }}</b>
              <ul class="nav">
                <li class="nav__item">
                  <RouterLink :to="localizedRoute({ name: 'all-categories' })">
                    {{ t('footer.all-categories') }}
                  </RouterLink>
                </li>
                <li class="nav__item">
                  <a :href="businessUrl"
                     target="_blank">
                    {{ t('nav.business') }}
                  </a>
                </li>
                <li class="nav__item">
                  <a :href="businessUrl"
                     target="_blank">
                    {{ t('nav.restaurant.register') }}
                  </a>
                </li>
                <li class="nav__item">
                  <a href="https://www.bistroo.nl/blog/"
                     target="_blank">
                    {{ t('nav.blog') }}
                  </a>
                </li>
              </ul>
            </div>
          </Column>

          <Column class="col-lg-3">
            <div class="footer__nav">
              <b>{{ t('footer.overview-cities') }}</b>
              <ul class="nav">
                <li v-for="city in cities"
                    :key="city.slug"
                    class="nav__item">
                  <a :href="localizedRoute({ name: 'slug', params: { slug: city.slug } }).href">
                    {{ city.name }}
                  </a>
                </li>
                <li class="nav__item">
                  <RouterLink :to="localizedRoute({ name: 'all-cities' })">
                    {{ t('cities.all') }}
                  </RouterLink>
                </li>
              </ul>
            </div>
          </Column>

          <Column class="col-lg-3">
            <div class="footer__nav">
              <b>{{ t('footer.other') }}</b>
              <ul class="nav">
                <li class="nav__item">
                  <RouterLink :to="localizedRoute({ name: 'customer-support' })">
                    {{ t('nav.customer-support') }}
                  </RouterLink>
                </li>
                <li class="nav__item">
                  <RouterLink :to="localizedRoute({ name: 'marketplace', query: $store.getters['session/appEstablishmentsQueryString'] })">
                    {{ t('terms.all') }} {{ t('terms.restaurants') }}
                  </RouterLink>
                </li>
              </ul>
            </div>
          </Column>

          <Column class="col-lg-3">
            <div class="footer__nav footer__nav--social">
              <b>{{ t('footer.follow-us') }}</b>
              <ul class="nav">
                <li class="nav__item nav__item--instagram">
                  <a class="nav__link"
                     href="https://www.instagram.com/bistroo.nl/"
                     target="_blank"
                     rel="noopener nofollow">
                    <img src="/assets/img/social/instagram.svg"
                         alt="Instagram"
                         height="14"
                         width="14" />
                  </a>
                </li>
                <li class="nav__item nav__item--tiktok">
                  <a class="nav__link"
                     href="https://www.tiktok.com/@bistroo.nl"
                     target="_blank"
                     rel="noopener nofollow">
                    <img src="/assets/img/social/tiktok.svg"
                         alt="TikTok"
                         height="12"
                         width="14" />
                  </a>
                </li>
                <li class="nav__item">
                  <a class="nav__link"
                     href="https://www.facebook.com/bistroo.nl/"
                     target="_blank"
                     rel="noopener nofollow">
                    <img src="/assets/img/social/facebook.svg"
                         alt="Facebook"
                         height="14"
                         width="8" />
                  </a>
                </li>
                <li class="nav__item nav__item--youtube">
                  <a class="nav__link"
                     href="https://www.youtube.com/channel/UC5izSfaX3qpsYXt6V3lFB1A"
                     target="_blank"
                     rel="noopener nofollow">
                    <img src="/assets/img/social/youtube.svg"
                         alt="YouTube"
                         width="14"
                         height="10" />
                  </a>
                </li>
              </ul>
            </div>
            <Breadcrumbs class="mt-4" />
          </Column>
        </Row>
      </div>

      <div class="footer__terms">
        <FooterReviews class="footer__reviews" />
        <ul class="nav">
          <li class="nav__item">
            <RouterLink :to="localizedRoute({ name: 'terms' })">
              {{ t('nav.terms-and-conditions') }}
            </RouterLink>
          </li>
          <li class="nav__item">
            <RouterLink :to="localizedRoute({ name: 'privacy' })">
              {{ t('nav.privacy-statement') }}
            </RouterLink>
          </li>
          <li class="nav__item">
            <RouterLink :to="localizedRoute({ name: 'cookie' })">
              {{ t('nav.cookie-statement') }}
            </RouterLink>
          </li>
          <li class="nav__item">
            &copy; {{ currentDate.getFullYear() }} - Bistroo -
            {{ t('footer.rights') }}
          </li>
          <li class="nav__item nav__item--version">
            v{{ version }}
          </li>
        </ul>
      </div>
    </Container>
  </footer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { version } from '../../../../package.json'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import Breadcrumbs from '@/components/SEO/Breadcrumbs.vue'
import Container from '@/components/Structure/Container/Container.vue'
import Row from '@/components/Structure/Row/Row.vue'
import Column from '@/components/Structure/Column/Column.vue'
import FooterFaq from './FooterFaq/FooterFaq.vue'
import FooterReviews from './FooterReviews/FooterReviews.vue'

export default {
  name: 'Footer',

  components: {
    Breadcrumbs,
    Container,
    Row,
    Column,
    FooterFaq,
    FooterReviews,
  },

  setup() {
    const { t, locale } = useI18n()
    const { localizedRoute } = useLocalizedRouter()

    return {
      t,
      locale,
      localizedRoute,
    }
  },

  data() {
    return {
      currentDate: new Date(),
      review: null,
      cities: [
        { name: 'Almere', slug: 'almere' },
        { name: 'Amsterdam', slug: 'amsterdam' },
        // { name: 'Bladel', slug: 'bladel' },
        // { name: 'Eersel', slug: 'eersel' },
        { name: 'Eindhoven', slug: 'eindhoven' },
        { name: 'Rotterdam', slug: 'rotterdam' },
        { name: 'Tilburg', slug: 'tilburg' },
        // { name: 'Westerhoven', slug: 'westerhoven' },
      ],
    }
  },

  computed: {
    businessUrl() {
      return this.locale === 'nl'
        ? 'https://www.bistroo.nl/zakelijk/'
        : 'https://www.bistroo.nl/en/business/'
    },

    version() {
      return version
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.footer {
  $self: &;
  padding-top: 2rem;
  background-color: #fff;

  .container {
    padding: 0 1.5rem;
  }

  @include lg-up {
    padding-top: 3rem;

    .container {
      padding: 0;
    }
  }

  &__reviews {
    padding-bottom: 3rem;
    @include dotted-border-bottom;

    @include lg-up {
      padding: 0;
      background-image: none;
    }
  }

  &__main {
    border-bottom: 1px solid var(--color-neutral-tertiary);
    padding: 0 0 6rem 0;
    position: relative;
  }

  &__nav {
    .nav {
      list-style: none;
      padding: 0;
      margin: 0;

      &__item {
        margin: 0.875rem 0;
      }
    }

    @include xl-down {
      margin-bottom: 2rem;
    }

    &--social {
      .nav {
        margin: 0rem -0.3125rem 0 -0.3125rem;
        display: flex;

        &__link {
          margin: 0 0.3125rem;
          border-radius: 50%;
          border: 1px solid var(--color-neutral-tertiary);
          height: 2.5rem;
          width: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__link {
          img {
            display: block;
          }
        }
      }
    }
  }

  &__terms {
    padding: 3rem 0;
    display: block;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size-sm;

    @include lg-up {
      display: flex;
    }

    .nav {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.5rem 2rem -0.5rem;
      list-style: none;
      padding: 0;

      @include lg-up {
        margin: 0 -0.5rem;
      }

      &__item {
        padding: 0.5rem;
        width: 50%;
        flex-shrink: 0;

        &--version {
          color: var(--color-neutral-tertiary);
        }

        @include lg-up {
          margin: 0 0.5rem;
          padding: 0;
          width: auto;
          flex-shrink: 1;
        }
      }
    }
  }
}
</style>
