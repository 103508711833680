<template>
  <div>
    <!-- DYNAMIC MODAL -->
    <ModalDynamic />

    <!-- CART CHANGED MODAL -->
    <Modal :open="cartchangedModalOpen"
      :closable="true"
      data-test-id="modal-cartchanged-container"
      @close="
        $store.dispatch('modals/toggle', {
          modal: CARTCHANGED_MODAL,
          open: false,
        })
      "
    >
      <ModalCartChanged />
    </Modal>

     <!-- ERROR MODAL -->
     <Modal :open="carterrorModalOpen"
      :closable="true"
      data-test-id="modal-error-container"
      @close="
        $store.dispatch('modals/toggle', {
          modal: ERROR_MODAL,
          open: false,
        })
      "
    >
      <ModalError />
    </Modal>

    <!-- Login / Signup Modals -->
    <Modal
      data-test-id="modal-login"
      :open="$store.getters['modals/open'](LOGIN_MODAL)"
      @close="
        $store.dispatch('modals/toggle', { modal: LOGIN_MODAL, open: false })
      "
    >
      <div class="modal__login">
        <LoginForm />
      </div>
    </Modal>

    <!-- NOT HERE YET MODAL -->
    <ModalNotHereYet />
  </div>
</template>

<script>
import { LOGIN_MODAL, CARTCHANGED_MODAL, ERROR_MODAL } from '@/store/modals'

// Component imports
import LoginForm from '@/components/Forms/LoginForm/LoginForm.vue'
import Modal from '@/components/Modals/Modal.vue'
import ModalDynamic from '@/components/Modals/ModalDynamic.vue'
import ModalNotHereYet from '@/components/Modals/ModalNotHereYet.vue'
import ModalCartChanged from '@/components/Modals/ModalCartChanged.vue'
import ModalError from '@/components/Modals/ModalError.vue'

export default {
  name: 'Modals',

  components: {
    LoginForm,
    Modal,
    ModalDynamic,
    ModalNotHereYet,
    ModalCartChanged,
    ModalError,
  },

  data() {
    return { LOGIN_MODAL, CARTCHANGED_MODAL, }
  },

  computed: {
    carterrorModalOpen(){
      return (this.$store.getters['modals/open'](ERROR_MODAL))
    },
    cartchangedModalOpen() {
      // If the store says so, we should show a location modal
      if (this.$store.getters['modals/open'](CARTCHANGED_MODAL)) return true

      // Otherwise: don't show it
      return false
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/mixins/breakpoints-up.scss';

.modal {
  &__login {
    width: 100%;
    margin-top: 1rem;

    @include lg-up {
      width: 30rem;
    }
  }
}
</style>
