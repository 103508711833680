<template>
  <div class="tooltip"
       :class="{ active: active, 'tooltip--with-footer': $slots.footer }">
    <div class="tooltip__element" @click.stop="toggle">
      <slot :active="active" />
    </div>
    <div v-on-click-outside="clickOutside"
         class="tooltip__dropdown">
      <div v-body-scroll-lock="lockDocumentBody"
           class="tooltip__inner">
        <div v-if="closable"
             class="tooltip__close"
             :class="{'active': active}"
             data-test-id="tooltip-close"
             @click.stop="() => (active && closable ? close() : null)">
          <Icon name="cross" />
        </div>
        <div class="tooltip__content">
          <slot name="content" />
        </div>
      </div>
      <div v-if="$slots.footer"
           class="tooltip__footer">
        <slot name="footer" />
      </div>
    </div>
    <div class="dark-overlay" :class="{'active': active}"></div>

  </div>

</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import Icon from '@/components/partials/Icon.vue'
import { vOnClickOutside } from '@vueuse/components'
import { useResizeObserver } from '@/composables/resizeObserver'

const props = defineProps({
  activeOnClick: { type: Boolean, default: true },
  closable: { type: Boolean, default: false },
})

const emit = defineEmits(['open-tooltip', 'close-tooltip'])

const { val } = useResizeObserver()

const active = ref(false)
const isHot = ref(false)

const lockDocumentBody = computed(() => active.value && val.value <= 576)

function makeHot() {
  isHot.value = true

  window.setTimeout(() => {
    isHot.value = false
  }, 250)
}

function open() {
  if (isHot.value) return

  active.value = true
  emit('open-tooltip')
  makeHot()
}

function close() {
  if (isHot.value) return

  active.value = false
  emit('close-tooltip')
  makeHot()
}

function toggle() {
  if (!props.activeOnClick) {
    return
  }

  if (active.value) {
    close()
  } else {
    open()
  }
}

function clickOutside() {
  if (active.value) {
    close()
  }
}

defineExpose({
  open,
  close,
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/interactions.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  &.active {
    @include lg-down{
      opacity: 1;
      transition: opacity 200ms ease-in-out;
      z-index: 30;
    }
  }
}

.tooltip {
  $self: &;
  z-index: 50;
  position: relative;

  &__element {
    @include hover {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__close {
    position: fixed;
    cursor: pointer;
    z-index: 10;
    right: 1.2rem;
    padding: 0.75rem 0.75rem 0.4rem;
    border: 1px solid var(--color-neutral-quaternary);
    border-radius: 0.5rem;

    &.active{
      z-index: 1000;
    }

    @include lg-up {
      display: none;
    }
  }

  &__dropdown {
    visibility: hidden;
    z-index: 50;
    background-color: #ffffff;
    top: calc(90px + env(safe-area-inset-top));
    right: 0;
    transform: scale(0);
    transform-origin: top;
    opacity: 0;
    position: fixed;
    height: calc(var(--app-height) - 60px - env(safe-area-inset-top));
    width: 100%;
    overflow: auto;
    transition: transform 150ms ease-in-out;
    // margin-top: 1rem;

    @include lg-up {
      position: absolute;
      border-radius: 1rem;
      margin-top: 4.5rem;
      box-shadow: 0px 0px 120px 10px rgba(37, 81, 138, 0.25);
      top: auto;
      width: auto;
      height: auto;

      &:after {
        display: block;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 0.75rem solid transparent;
        border-bottom-color: #ffffff;
        top: -1.5rem;
        right: 2rem;
      }
    }
  }

  &__inner {
    @include bistroo-scrollbar;
    height: 100%;
    position: relative;
    overflow-y: auto;
    padding: 1.5rem;
  }

  &--with-footer {
    #{$self}__inner {
      height: calc(100% - 72px);
    }
  }

  &__content {
    display: block;
    width: auto;
    min-height: 100%;

    @include lg-up {
      min-height: auto;
    }
  }

  &__footer {
    padding: 1rem;
    background: #fff;
    border-top: 1px solid var(--color-neutral-quaternary);
  }

  &.active {
    .tooltip__dropdown {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
      transform-origin: calc(100% - 1.5rem) calc(0% + 4rem);
      transition: all 150ms ease-in-out;
    }
    :deep(.button){
      z-index:500;
    }
  }
}
</style>
