<script setup>
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Headline from '@/components/partials/Headline.vue'
import { ERROR_MODAL } from '@/store/modals'
import  { useStore } from 'vuex'
const { t, } = useI18n()

const error = ref({})
const store = useStore()

onMounted(() => {
  if (store.getters['modals/data'](ERROR_MODAL).error)
    error.value = store.getters['modals/data'](ERROR_MODAL).error
})

</script>

<template>
  <div class="modal-error"
    data-test-id="modal-error"
  >
    <Headline>
      <div class="modal-error__title modal-error__title--lg">
        {{ t('messages.something_went_wrong') }}
      </div>
    </Headline>

    <div class="modal-error__products">
      <p class="modal-error_text">{{ t('errors.products-updated') }}</p><br>
    </div>


    <div class="modal-error__buttons">
      <Button type="primary"
              @click="
                $store.dispatch('modals/toggle', {
                  modal: ERROR_MODAL,
                  open: false,
                })">
        {{ t('buttons.confirm') }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';

.modal-error {
  width: 100%;

  @include lg-up {
    width: 35rem;
  }

  &__title {
    font-weight: 700;
    margin-bottom: 1rem;

    &--lg {
      font-size: 1.125rem;
      margin-bottom: 0;
    }
  }

  &__products {
    margin: 2rem 0;
  }

  &__product {
    padding: 1rem 0;
    display: flex;
    align-items: center;

    @include icon-svg(1.5rem, 1.5rem) {
      margin-right: 0.5rem;
      color: var(--color-neutral-secondary);
    }
  }

  &__buttons {
    display: flex;
    justify-content: right;
    margin-top: 1rem;
    > *  {
      margin-left: 1rem;
    }

    > button {
      display: none;

      @include lg-up {
        display: block;
      }
    }
  }
}
</style>
