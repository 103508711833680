<script>
import { h } from 'vue'
export default {
  setup(_, { slots }) {
    return () =>
      h('script', {
        type: "application/ld+json",
        innerHTML: slots.default()[0].children,
      });
  },
}
</script>