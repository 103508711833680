<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import IconDottedOutline from '@/components/partials/IconDottedOutline/IconDottedOutline.vue'
import { useLocalizedRouter } from '@/composables/localizedRouter'

const { t } = useI18n()
const { localizedRoute } = useLocalizedRouter()
</script>

<template>
  <router-link :to="localizedRoute({ name: 'customer-support' })" class="footer-faq">
    <IconDottedOutline
      class="footer-faq__icon"
      icon="speech-bubbles"
    />
    <span>
      <h4>{{ t('footer.faq.title') }}</h4>
      <p>{{ t('footer.faq.description') }}</p>
    </span>
  </router-link>
</template>

<style lang="scss" scoped>
@import '@/assets/css/variables/variables.scss';

.footer-faq {
    display: inline-flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 3rem;
    padding: 1rem 2rem 1rem 1rem;
    border: 1px solid transparent;
    margin-left: -1rem;
    transition: all 0.2s ease;
    border-radius: 1rem;

    &__icon:deep(.b-icon-icon svg) {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      margin-left: 0rem;
      border-color: $new-color-neutral-quaternary;
    }
  }

</style>
