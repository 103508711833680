<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { validateEmail } from '@/plugins/globals/validate-email'
import { Form, FormHeader } from '@/components/Form'
import Row from '@/components/Structure/Row/Row.vue'
import Column from '@/components/Structure/Column/Column.vue'
import TextInput from '@/components/partials/Inputs/TextInput.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Warning from '@/components/partials/Warning.vue'

defineProps<{
  error?: string | null
  layoutTypes: any
}>()

type RegisterFormState = {
  name: string
  email: string
  password: string
  passwordConfirmation: string
}

const emit = defineEmits<{
  (e: 'change-layout', id: number): void
  (e: 'register', value: RegisterFormState): void
}>()

const { t } = useI18n()

const rules = computed(() => ({
  name: {
    required,
  },
  email: {
    validEmail(value: string) {
      return validateEmail(value)
    },
  },
  password: {
    required,
    minLength: minLength(12),
  },
  passwordConfirmation: {
    required,
    // @ts-ignore
    same: function () {
      return state.password === state.passwordConfirmation
    },
  },
}))

const state = reactive({
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
})

const v$ = useVuelidate(rules, state)

const register = () => {
  emit('register', {
    name: state.name,
    email: state.email,
    password: state.password,
    passwordConfirmation: state.passwordConfirmation,
  })
}

const computedError = computed(() => {
  if (v$.value.password.$errors.find((error) => error.$validator === 'required')) {
    return t('errors.password.required')
  }

  if (v$.value.password.$errors.find((error) => error.$validator === 'minLength')) {
    return t('errors.password.min-length',{ length: 12 })
  }

  return null
})
</script>

<template>
  <Form>
    <Row>
      <Column>
        <FormHeader data-test-id="register-header"
          action-data-test-id="header-back-button"
          :back-button-text="t('modal-account.buttons.back-to-login')"
          @back-button-click="emit('change-layout', layoutTypes.LOGIN)"
        >
          {{ t('modal-account.title.create-account') }}
        </FormHeader>
      </Column>
    </Row>
    <Row>
      <Column>
        <TextInput v-model.trim="v$.name.$model"
          data-test-id="name-input"
          :label="t('modal-account.inputs.name.label')"
          :placeholder="t('modal-account.inputs.name.placeholder')"
          :error="v$.name.$error ? t('errors.name.required') : null"
        />
      </Column>
    </Row>
    <Row>
      <Column>
        <TextInput v-model.trim="v$.email.$model"
                   data-test-id="email-input"
                   :label="t('modal-account.inputs.email.label')"
                   :placeholder="t('modal-account.inputs.email.placeholder')"
                   :error="v$.email.$error ? t('errors.email.required') : null"
        />
      </Column>
    </Row>
    <Row>
      <Column>
        <TextInput v-model.lazy="v$.password.$model"
                   data-test-id="password-input"
                   type="password"
                   :label="t('modal-account.inputs.password.label')"
                   :placeholder="t('modal-account.inputs.password.placeholder')"
                   :error="computedError"
        />
      </Column>
    </Row>
    <Row>
      <Column>
        <TextInput v-model.lazy="v$.passwordConfirmation.$model"
                   data-test-id="password-confirmation-input"
                   :label="t('modal-account.inputs.password-validation.label')"
                   type="password"
                   :error="
                     v$.passwordConfirmation.$error
                     ? t('errors.password-validation.required')
                     : null
                   "
                   :placeholder="
                     t('modal-account.inputs.password-validation.placeholder')
                   "
                  @hit-enter="register"
        />
      </Column>
    </Row>
    <Row v-if="error">
      <Column>
        <Warning type="warning" data-test-id="register-form-error">
          {{ error }}
        </Warning>
      </Column>
    </Row>
    <Row buttons>
      <Column>
        <Button data-test-id="register-button"
          :disabled="v$.$invalid"
          @click="register"
        >
          {{ t('modal-account.buttons.create-account') }}
        </Button>
      </Column>
    </Row>
  </Form>
</template>

<style lang="scss" scoped>
.password-meter {
  max-width: 100%;
  margin: 1rem 0.5rem 0;
}
</style>
