<script setup>
import { useI18n } from 'vue-i18n'
import { onMounted, ref, computed } from 'vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Headline from '@/components/partials/Headline.vue'
import ProductItem from '@/components/Modals/CartChangedModal/ProductItem.vue'
import { CARTCHANGED_MODAL } from '@/store/modals'
import  { useStore } from 'vuex'
const { t, } = useI18n()

const changes = ref({
  changes: [],
  removed: [],
})

const store = useStore()

const distributionType = computed(() => {
  const type = store.getters['modals/data'](CARTCHANGED_MODAL).distributionType
  const dist = ['takeaway', 'delivery', 'on-location',][type - 1]
  return t(`${dist}`).toLowerCase()
})

const confirmToggle = async () => {
  const type = store.getters['modals/data'](CARTCHANGED_MODAL).distributionType
  changes.value.new_distribution = Number(type)

  await store.dispatch('cart/applyCartChanges', changes.value)
  await store.dispatch('session/storeDistributionType', type)
  await store.dispatch('cart/storeDistributionType', type)
  await store.dispatch('cart/fetchBasketInfo')

  await store.dispatch('modals/toggle', {
    modal: CARTCHANGED_MODAL,
    open: false,
  })
}

async function cancelToggle() {
  await store.dispatch('session/storeDistributionType', store.state.session.distributionType)
  await store.dispatch('modals/toggle', {
    modal: CARTCHANGED_MODAL,
    open: false,
  })
}

onMounted(() => {
  if (store.getters['modals/data'](CARTCHANGED_MODAL).changes)
    changes.value.changes = store.getters['modals/data'](CARTCHANGED_MODAL).changes

  if (store.getters['modals/data'](CARTCHANGED_MODAL).removed)
    changes.value.removed = store.getters['modals/data'](CARTCHANGED_MODAL).removed
})
</script>

<template>
  <div
    class="modal-cartchanged"
    data-test-id="modal-cartchanged"
  >
    <Headline>
      <div class="modal-cartchanged__title modal-cartchanged__title--lg">
        <!-- {{ t('modal-address.provide-zipcode') }} -->
        {{ t('messages.products_not_available') }}
      </div>
    </Headline>

    <div class="modal-cartchanged__products" v-if="changes.removed.length > 0">
      <p class="modal-cartchanged_text">{{ t('confirmation-modal.change-distribution-type',{type: distributionType}) }}</p><br>
      <div ref="itemsContainer"
             class="cart__items"
        >
          <ProductItem v-for="product in changes.removed"
                    :key="product.product_id"
                    :product="product"
                    class="cart__item"
                    :with-image="false"
                    :with-point="false"
          />
        </div>
    </div>

    <div class="modal-cartchanged__products" v-if="changes.changes.length > 0 && false">
      <p>{{ t('messages.products_changed_for', [distributionType]) }}</p>
    </div>

    <div class="modal-cartchanged__buttons">
      <Button type="secondary" :disabled="loadingState || errorState"
              @click="cancelToggle">
        {{ t('buttons.cancel') }}
      </Button>

      <Button :disabled="loadingState || errorState"
              @click="confirmToggle">
        {{ t('buttons.confirm') }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';

.modal-cartchanged {
  width: 100%;

  @include lg-up {
    width: 35rem;
  }

  &__title {
    font-weight: 700;
    margin-bottom: 1rem;

    &--lg {
      font-size: 1.125rem;
      margin-bottom: 0;
    }
  }

  &__products {
    margin: 2rem 0;
  }

  &__product {
    padding: 1rem 0;
    display: flex;
    align-items: center;

    @include icon-svg(1.5rem, 1.5rem) {
      margin-right: 0.5rem;
      color: var(--color-neutral-secondary);
    }
  }

  &__buttons {
    display: flex;
    justify-content: right;
    margin-top: 1rem;
    > *  {
      margin-left: 1rem;
    }

    > button {
      display: none;

      @include lg-up {
        display: block;
      }
    }
  }
}
</style>
