<template>
  <div>
    <JsonLd>
      {{ structuredData }}
    </JsonLd>

    <div class="breadcrumbs" v-if="breadcrumbs.length">
      <b class="mr-1">{{ t('messages.you-are-here') }}:</b>
      <ol class="breadcrumbs__list">
        <li v-for="crumb in breadcrumbs"
            :key="crumb.path"
            class="breadcrumbs__item">
          <RouterLink class="breadcrumbs__link"
                      :to="crumb.path"
                      v-if="crumb.path">
            <span>{{ crumb.name }}</span>
          </RouterLink>
          <span v-else class="breadcrumbs__link">
            {{ crumb.name }}
          </span>
        </li>
      </ol>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from '@/stores'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import JsonLd from '@/components/partials/JsonLd.vue'
import CategoryResource from '@/resources/CategoryResource'

const route = useRoute()
const store = useStore()
const { t } = useI18n()
const { localizedRoute } = useLocalizedRouter()

const category = computed(() => store.getters['category/category'] !== null ? new CategoryResource(store.getters['category/category']) : null)
const establishment = computed(() => store.getters['establishment/establishment'])
const city = computed(() => store.getters['city/city'])

const homeCrumb = {
  name: 'Home',
  path: localizedRoute({ name: 'home' }).fullPath,
}

const useMarketplaceBreadcrumbs = () => {
  return [
    homeCrumb,
    {
      name: t('nav.marketplace'),
      path: localizedRoute({ name: 'marketplace'}),
    },
  ]
}

const useAllCategoriesBreadcrumbs = () => [
  homeCrumb,
  {
    path: localizedRoute({ name: 'all-categories' }).fullPath,
    name: t('categories.all'),
  },
]

const useCategoryBreadcrumbs = () => [
  homeCrumb,
  {
    path: localizedRoute({
      name: 'all-categories',
    }).fullPath,
    name: `${t('terms.all')} ${t('categories.categories').toLowerCase()}`,
  },
  {
    path: route.path,
    name: category.value.name,
  },
]

const useAllCitiesBreadcrumbs = () => [
  homeCrumb,
  {
    path: localizedRoute({ name: 'all-cities' }).fullPath,
    name: t('cities.all'),
  },
]

const useCityBreadcrumbs = () => [
  homeCrumb,
  {
    path: localizedRoute({ name: 'all-cities' }).fullPath,
    name: t('cities.all'),
  },
  {
    path: localizedRoute({
      name: 'slug',
      params: {
        slug: route.params.slug,
      },
    }).fullPath,
    name: city.value.name,
  },
]

const useEstablishmentBreadcrumbs = () => {
  const cityCrumb = {
    name: establishment.value ? establishment.value.location?.city : '',
    path: localizedRoute({
      name: 'slug',
      params: {
        slug: route.params.city,
      },
    }).fullPath,
  }

  const establishmentCrumb = {
    path: route.path,
    name: establishment.value?.name ?? 'restaurant',
  }

  return [
    homeCrumb,
    cityCrumb,
    establishmentCrumb,
  ]
}

const breadcrumbs = computed(() => {
  if (typeof route.name !== 'string') return []

  if (route.name.includes('establishment')) return useEstablishmentBreadcrumbs()

  if (route.name.includes('marketplace')) return useMarketplaceBreadcrumbs()

  if (route.name.includes('all-cities')) return useAllCitiesBreadcrumbs()

  if (route.name.includes('all-categories')) return useAllCategoriesBreadcrumbs()

  if (route.name.includes('slug') && category.value && Object.keys(category.value).length) return useCategoryBreadcrumbs()

  if (route.name.includes('slug') && city.value && Object.keys(city.value).length) return useCityBreadcrumbs()

  return [
    homeCrumb
  ]
})

const structuredData = computed(() => {
  if (!breadcrumbs.value.length) return null

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.value.map((crumb, index) => {
      if (!crumb.path) return {}

      return {
        '@type': 'ListItem',
        position: index + 1,
        name: crumb.name,
        item: `${import.meta.env.VITE_FRONTEND_URL}${crumb.path}`,
      }
    }),
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables/variables.scss';

.breadcrumbs {
  font-size: $font-size-sm;

  &__list {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: inline-block;

    &:after {
      content: '›';
      margin: 0 0.5em;
      color: var(--color-neutral-secondary);
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}
</style>
