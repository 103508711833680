<template>
  <div v-if="review"
       class="footer-reviews"
  >
    <img src="/assets/img/footer/trustpilot-star.svg" alt="Trustpilot">
    <div>
      {{ t('footer.review.title') }}<br>
      <i18n-t keypath="footer.review.value"
              scope="global"
      >
        <template #value>
          <b>{{ review.ratingValue }}</b>
        </template>
      </i18n-t>
      -
      <i18n-t keypath="footer.review.count"
              scope="global"
              tag="a"
              target="_blank"
              class="link"
              href="https://nl.trustpilot.com/review/bistroo.nl"
              rel="noopener"
      >
        <template #count>
          {{ review.reviewCount }}
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAxios } from '@/composables/useAxios'

export default {
  setup() {
    const { t } = useI18n()
    const review = ref(null)
    const axios = useAxios()

    onMounted(async() => {
      const { data: {
        businessUnit: {
          stars,
          numberOfReviews: { total },
        },
      }} = await axios({
        method: 'GET',
        url: 'pilot-tunnel',
        showErrorModal: false,
      })

      review.value = {
        ratingValue: stars,
        reviewCount: total,
      }
    })

    return {
      review,
      t,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables/variables.scss';

.footer-reviews {
  display: flex;
  gap: 1rem;
  line-height: $line-height-md;
}
</style>
