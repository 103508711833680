<template>
  <Form>
    <Row>
      <Column>
        <FormHeader data-test-id="recover-form-header"
          action-data-test-id="header-back-button"
          :back-button-text="t('modal-account.buttons.back-to-login')"
          @back-button-click="$emit('change-layout', layoutTypes.LOGIN)"
        >
          {{ t('modal-account.title.forgot-password') }}
        </FormHeader>
      </Column>
    </Row>

    <Row>
      <Column>
        <TextInput v-model.trim="v.email.$model"
          data-test-id="email-input"
          :label="t('modal-account.inputs.email.label')"
          :placeholder="t('modal-account.inputs.email.placeholder')"
          :error="v.email.$error ? t('errors.email.required') : null"
          @hit-enter="recover"
        />
      </Column>
    </Row>

    <Row v-if="error">
      <Column>
        <Warning type="warning"
          data-test-id="recover-error"
        >
          {{ error }}
        </Warning>
      </Column>
    </Row>

    <Row buttons>
      <Column>
        <Button :disabled="v.$invalid"
          data-test-id="recover-button"
          @click="recover"
        >
          {{ t('modal-account.buttons.reset-password') }}
        </Button>
      </Column>
    </Row>
  </Form>
</template>

<script>
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { validateEmail } from '@/plugins/globals/validate-email'

import { Form, FormHeader } from '@/components/Form'

import Row from '@/components/Structure/Row/Row.vue'
import Column from '@/components/Structure/Column/Column.vue'
import TextInput from '@/components/partials/Inputs/TextInput.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Warning from '@/components/partials/Warning.vue'

export default {
  name: 'RecoverFormComponent',

  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Row,
    Column,
    FormHeader,
    TextInput,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    Warning,
  },

  props: {
    layoutTypes: { type: Object, required: true },
    header: { type: Boolean, default: true },
    error: { type: String, default: null },
  },

  emits: ['change-layout', 'recover'],

  setup() {
    const { t } = useI18n()

    return {
      v: useVuelidate(),
      t,
    }
  },

  data() {
    return {
      // User data (connected to vuelidate)
      email: '',
    }
  },

  methods: {
    recover() {
      this.v.$touch()

      if (!this.v.$invalid) {
        this.$emit('recover', {
          email: this.email,
        })
      }
    },
  },

  validations: {
    email: {
      required,
      validEmail(value) {
        return validateEmail(value)
      },
    },
  },
}
</script>
