<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { Capacitor } from '@capacitor/core'
import { useLocalizedRouter } from '@/composables/localizedRouter'

const { t } = useI18n()
const { localizedRoute } = useLocalizedRouter()

const isNative = Capacitor.isNativePlatform()
</script>

<template>
  <div class="menu-tooltip-list">
    <ul class="menu-tooltip-list__list">
      <li class="menu-tooltip-list__item">
        <RouterLink v-taptic="'selection'" :to="localizedRoute({ name: 'marketplace' })">{{ t('nav.marketplace') }}</RouterLink>
      </li>
      <li class="menu-tooltip-list__item">
        <RouterLink v-taptic="'selection'" :to="localizedRoute({ name: 'customer-support' })">{{ t('nav.customer-support') }}</RouterLink>
      </li>
      <li class="menu-tooltip-list__item">
        <a v-if="!isNative" v-taptic="'selection'" href="https://www.bistroo.nl/zakelijk/" target="_blank">
          {{ t('nav.for-establishments') }}
        </a>
      </li>
      <li class="menu-tooltip-list__item">
        <a v-taptic="'selection'" href="https://www.bistroo.nl/blog/" target="_blank">
          {{ t('nav.blog') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.menu-tooltip-list {
  position: relative;

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    margin-bottom: 1.5rem;

    span, a {
      cursor: pointer;
    }
  }
}
</style>
