<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'

import { Form, FormHeader } from '@/components/Form'
import Row from '@/components/Structure/Row/Row.vue'
import Column from '@/components/Structure/Column/Column.vue'
import TextInput from '@/components/partials/Inputs/TextInput.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Warning from '@/components/partials/Warning.vue'

defineProps({
  email: { type: String, default: null },
  layoutTypes: { type: Object, required: true },
  header: { type: Boolean, default: true },
  error: { type: String, default: null },
})

const emit = defineEmits(['change-layout', 'recover'])

const { t } = useI18n()

const state = reactive({
  password: '',
  passwordConfirmation: '',
})

const rules = computed(() => ({
  password: {
    required,
    minLength: minLength(12),
  },
  passwordConfirmation: {
    required,
    minLength: minLength(12),
    sameAs: sameAs(state.password),
  },
}))

const v$ = useVuelidate(rules, state)

function recover() {
  emit('recover', {
    password: state.password,
    passwordConfirmation: state.passwordConfirmation,
  })
}

const computedError = computed(() => {
  if (v$.value.password.$errors.find((error) => error.$validator === 'required')) {
    return t('errors.password.required')
  }

  if (v$.value.password.$errors.find((error) => error.$validator === 'minLength')) {
    return t('errors.password.min-length', {length: 12})
  }

  return null
})
</script>

<template>
  <Form>
    <Row>
      <Column>
        <FormHeader data-test-id="recovering-form-header"
          action-data-test-id="header-back-button"
          :back-button-text="t('modal-account.buttons.back-to-login')"
          @back-button-click="$emit('change-layout', layoutTypes.LOGIN)"
        >
          {{ t('modal-account.title.reset-password') }}
        </FormHeader>
      </Column>
    </Row>

    <Row>
      <Column>
        <TextInput disabled
          data-test-id="email-input"
          :model-value="email"
          :label="t('modal-account.inputs.email.label')"
          :placeholder="t('modal-account.inputs.email.placeholder')"
        />
      </Column>
    </Row>

    <Row>
      <Column>
        <TextInput v-model.lazy="v$.password.$model"
          data-test-id="password-input"
          type="password"
          :label="t('modal-account.inputs.password.label')"
          :placeholder="t('modal-account.inputs.password.placeholder')"
          :error="computedError"
        />
      </Column>
    </Row>

    <!-- Password validation -->
    <Row>
      <Column>
        <TextInput v-model.lazy="v$.passwordConfirmation.$model"
          data-test-id="password-confirm-input"
          type="password"
          :label="t('modal-account.inputs.password-validation.label')"
          :error="
            v$.passwordConfirmation.$error
              ? t('errors.password-validation.required')
              : null
          "
          :placeholder="
            t('modal-account.inputs.password-validation.placeholder')
          "
        />
      </Column>
    </Row>

    <Row v-if="error">
      <Column>
        <Warning type="warning">
          {{ error }}
        </Warning>
      </Column>
    </Row>

    <Row>
      <Column>
        <Button :disabled="v$.$invalid"
          data-test-id="reset-button"
          @click="recover"
        >
          {{ t('modal-account.buttons.reset-password') }}
        </Button>
      </Column>
    </Row>
  </Form>
</template>

<style lang="scss" scoped>
.password-meter {
  width: 100%;
  max-width: 100%;
}
</style>
