<template>
  <div
    class="divider"
    :class="{ ['divider--' + type]: type }"
  />
</template>
<script>
export default {
  name: 'Divider',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped>
.divider {
  width: 100%;
  display: block;
  border-top: 1px solid var(--color-neutral-tertiary);
  &--dashed {
    border-top: 1px dashed var(--color-neutral-tertiary);
  }
}
</style>
