<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import BIcon from '@/components/partials/Icon.vue'
import { useLocalizedRouter } from '@/composables/localizedRouter'

const emit = defineEmits(['close-tooltip'])

const { t } = useI18n()

const router = useRouter()

const { localizedRoute } = useLocalizedRouter()

async function goToAccount() {
  await router.push(localizedRoute({ name: 'customer-account' }))

  emit('close-tooltip')
}
</script>

<template>
  <div class="menu-tooltip-account" @click="goToAccount">
    <BIcon name="user" class="menu-tooltip-account__icon" />
    <span class="menu-tooltip-account__name">{{ t('terms.account') }}</span>
    <span class="menu-tooltip-account__go">
      <BIcon name="chevron" dir="left" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
.menu-tooltip-account {
  position: relative;
  padding: 1rem 1.5rem;
  margin: 0 -1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__name {
    margin-left: 0.75rem;
  }

  &__go {
    margin-left: auto;
    transform: scale(0.8);
  }
}
</style>
