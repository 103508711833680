<template>
  <Modal :open="$store.getters['modals/open'](NOT_HERE_YET_MODAL)"

    data-test-id="modal-not-here-yet"
    @close="
      $store.dispatch('modals/toggle', {
        modal: NOT_HERE_YET_MODAL,
        open: false,
      })
    "
  >
    <div class="modal-not-here-yet">
      <img src="/assets/img/not-here-yet.png"
        :alt="t('images.nothereyet')"
        class="modal-not-here-yet__image"
      >

      <Headline>
        <h1 class="modal-not-here-yet__title">
          {{ t('errors.not-here-yet-title') }}
        </h1>
        <p class="modal-not-here-yet__message">
          {{ t('errors.not-here-yet-message') }}
        </p>
      </Headline>
    </div>

    <template #bottom>
      <TextInput v-model="email"
        :error="error"
        :placeholder="t('email.placeholder')"
        :disabled="sent"
        rounded
        @hit-enter="saveEmail"
      />
      <Button :type="sent ? 'secondary' : null"
        @click="saveEmail"
      >
        <template v-if="sent">
          <Icon name="check" /> {{ t('email.sent') }}
        </template>
        <template v-else>
          {{ t('email.send') }}
        </template>
      </Button>
    </template>
  </Modal>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import { validateEmail } from '@/plugins/globals/validate-email'
import { NOT_HERE_YET_MODAL } from '@/store/modals'
import Modal from '@/components/Modals/Modal.vue'
import Icon from '@/components/partials/Icon.vue'
import TextInput from '@/components/partials/Inputs/TextInput.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Headline from '@/components/partials/Headline.vue'
import { NOTHEREYET_SUBSCRIBED } from "@/plugins/globals/segment/handlers";

export default {
  name: 'ModalNotHereYet',

  components: {
    Modal,
    Icon,
    TextInput,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    Headline,
  },

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },

  data() {
    return { email: null, error: null, sent: false, NOT_HERE_YET_MODAL }
  },

  computed: {
    ...mapGetters({
      user: 'user/user',
      location: 'session/location',
      outOfRangeLocation: 'session/outOfRangeLocation',
    }),
  },

  methods: {
    saveEmail() {
      this.error = null

      if (this.sent) return

      if (!validateEmail(this.email)) {
        this.error = this.t('errors.email.required')
        return
      }

      this.$segment.handle(NOTHEREYET_SUBSCRIBED, {
        email: this.email,
        location: this.outOfRangeLocation ?? this.location
      })

      this.sent = true

      this.$store.dispatch('session/storeOutOfRangeLocation', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-not-here-yet {
  max-width: 25rem;

  &__image {
    width: 7rem;
    margin-bottom: 1rem;
  }

  &__title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__field-set {
    margin-bottom: 1rem;
  }
}
</style>
